// 通用
export default {
    namespaced: true,
    state() {
        return {
            menus: [],
            activeTab: "home" // 当前显示标签
        };
    },
    mutations: {
        setMenus(state, value) {
            state.menus = value;
        },
        setActiveTab(state, activeTab) {
            state.activeTab = activeTab;
        }
    }
};
