import Vue from "vue";
import Vuex from "vuex";
import global from "./modules/global";
import createPersistedstate from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        appShow: true,
        isLoad: false,
        admin: localStorage.getItem("admin") ? JSON.parse(localStorage.getItem("admin")) : null
    },
    getters: {
        admin: state => state.admin
    },
    mutations: {
        updateAppShow(state, appShow) {
            state.appShow = appShow;
        },
        updateIsLoad(state, isLoad) {
            state.isLoad = isLoad;
        },
        updateAdmin(state, admin) {
            state.admin = admin;
        }
    },
    actions: {},
    modules: {
        global
    },
    plugins: [
        createPersistedstate({
            key: "erabbit-client-pc-store",
            paths: ["global"]
        })
    ]
});
