import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import axios from "axios";
import VueAxios from "vue-axios";
import qs from "qs";
import { MessageBox } from 'element-ui'
import VueCookies from "vue-cookies";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// import md5 from 'js-md5'; // 引入 md5 库 
import infiniteScroll from "vue-infinite-scroll";
  

Vue.use(VueCookies);
Vue.use(infiniteScroll);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Antd);
axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
Vue.use(VueAxios, axios);
Vue.prototype.$confirm = MessageBox.confirm
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
    if (to.meta.loginRequest) {
        if (localStorage.getItem("/")) {
            next("/");
        } else {
            next({
                path: "/login",
                query: {
                    redirect: to.fullPath
                }
            });
        }
    }
});
axios.defaults.transformRequest = [
    (data, header) => {
        return qs.stringify(data);
    }
];

NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })
router.beforeEach((to, from, next) => {
    NProgress.start()
    next()
  })
  
  router.afterEach(() => {
    NProgress.done()
  })

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount("#app");
