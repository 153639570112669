import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/home.vue";
import store from "../store/index";
import { message } from "ant-design-vue";
Vue.use(VueRouter);

const routes = [  
    {  
        path: "/login",  
        name: "login",  
        component: () => import("../views/login.vue"),  
        meta: {  
            title: "苏雪茗官网管理系统"  
        }  
    },  
    {  
        path: "/",
        name: "index",
        redirect: "/home", // 重定向
        component: () => import("../views/index.vue"),
        meta: {
            title: "首页"
        },
        children: [
            {
                path: "/home",
                name: "home",
                component: () => import("../views/home.vue"),
                meta: {
                    title: "苏雪茗官网管理系统"
                }
            },{
                path: "/homeset",
                name: "homeset",
                component: () => import("../views/homeset.vue"),
                meta: {
                    title: "首页设置"
                }
            },{
                path: "/site",
                name: "site",
                component: () => import("../views/site.vue"),
                meta: {
                    title: "站点信息"
                }
            },{
                path: "/system",
                name: "system",
                component: () => import("../views/system.vue"),
                meta: {
                    title: "系统用户"
                }
            },{
                path: "/brand",
                name: "brand",
                component: () => import("../views/brand.vue"),
                meta: {
                    title: "品牌介绍"
                }
            },{
                path: "/honor",
                name: "honor",
                component: () => import("../views/honor.vue"),
                meta: {
                    title: "荣誉篇章"
                }
            },{
                path: "/product",
                name: "product",
                component: () => import("../views/product.vue"),
                meta: {
                    title: "产品管理"
                }
            },{
                path: "/news",
                name: "news",
                component: () => import("../views/news.vue"),
                meta: {
                    title: "新闻管理"
                }
            },{
                path: "/store",
                name: "store",
                component: () => import("../views/store.vue"),
                meta: {
                    title: "门店管理"
                }
            },{
                path: "/franchise",
                name: "franchise",
                component: () => import("../views/franchise.vue"),
                meta: {
                    title: "加盟申请"
                }
            },{
                path: "/cost",
                name: "cost",
                component: () => import("../views/cost.vue"),
                meta: {
                    title: "费用设置"
                }
            },{
                path: "/display",
                name: "display",
                component: () => import("../views/display.vue"),
                meta: {
                    title: "形象展示"
                }
            },{
                path: "/dictionary",
                name: "dictionary",
                component: () => import("../views/dictionary.vue"),
                meta: {
                    title: "字典管理"
                }
            },{
                path: "/citydata",
                name: "citydata",
                component: () => import("../views/citydata.vue"),
                meta: {
                    title: "城市数据"
                }
            },{
                path: "/systemp",
                name: "systemp",
                component: () => import("../views/systemp.vue"),
                meta: {
                    title: "系统配置"
                }
            }
        ]
    }  
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    // next();
    // return;
    // 判断跳转页面是否为登录页面
    if (to.path === "/login") {
        next();
        return;
    }

    if (localStorage.getItem("token")) {
        if (!store.state.isLoad) {
            store.commit("updateIsLoad", true);
            if (to.name === "home") {
                next();
            } else {
                next("/");
            }
            return;
        }

        // 首页无需添加
        if (to.name !== "home") {
            let menus = store.state.global.menus;
            let isSave = false;
            menus.some((item) => {
                if (item.name === to.name) {
                    isSave = true;
                    return true;
                }
            });
            if (!isSave) {
                let obj = {
                    path: to.path,
                    title: to.meta.title,
                    name: to.name
                }
                menus.push(obj);
            }
            store.commit("global/setMenus", menus);
        }
        store.commit("global/setActiveTab", to.name);
        next();
    } else {
        Vue.$cookies.remove("token");
        localStorage.removeItem("token");
        message.warning("请登录!");
        next("/login");
    }
});

export default router;
