<template>
  <a-config-provider :locale="locale">
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import md5 from "js-md5"; // 引入 md5 库
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  computed: {
    appShow: {
      get() {
        return this.$store.state.appShow;
      },
    },
  },
};
</script>
<style>
.is-upload .el-upload--picture-card{
  display: none;
}
* {
  padding: 0;
  margin: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  height: 100%;
}
#nprogress .bar {
  background: #9ad2bd !important;
}
.el-button--small:hover {
color: #fff !important;
background-color: #9ad2bd !important;
border-color: #9ad2bd !important;
}
.el-button--primary {
    background-color: #9ad2bd !important;
    border-color: #9ad2bd !important;
  }
  .el-button--primary:hover {
    background-color: #9ad2bd !important;
    border-color: #9ad2bd !important;
  }
  .el-button--default:hover{
    background-color: #9ad2bd !important;
    border-color: #9ad2bd;
    color: #9ad2bd;
  }
  .el-pagination__item:hover{
    background-color: #ffffff !important;
    border-color: #9ad2bd;
    color: #9ad2bd;
  }
  .el-input__inner:focus {
    border-color: #9ad2bd !important; /* 替换 #你的颜色代码 为你想要的颜色 */
  }

  .el-input__inner:hover {
    border-color: #9ad2bd !important; /* 替换 #你的颜色代码 为你想要的颜色 */
  }
  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #9ad2bd !important;
    border-color: #9ad2bd;
  }

  .el-checkbox__inner:hover {
    border-color: #9ad2bd;
  }

  .el-checkbox__input:focus {
    border-color: #9ad2bd;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #9ad2bd;
  }

  .el-pager li.active {
    color: #9ad2bd;
  }
  .el-select-dropdown .el-select-dropdown__item.selected {   
    color: #fff;
}  
.el-textarea__inner:focus {
    border-color: #9ad2bd !important; /* 替换 #你的颜色代码 为你想要的颜色 */
  }
  .el-textarea__inner:hover {
    border-color: #9ad2bd !important; /* 替换 #你的颜色代码 为你想要的颜色 */
  }
 .el-range-editor.is-active,  
 .el-range-editor.is-active:hover,  
 .el-select .el-input.is-focus .el-input__inner {  
    border-color: #9ad2bd; /* 将边框颜色更改为红色 */  
}
.el-select {
  width:100%
}
.el-checkbox__input.is-checked .el-checkbox__inner, 
.el-checkbox__input.is-indeterminate .el-checkbox__inner{  
    background-color: #9ad2bd !important;  
    border-color: #9ad2bd;
}  
.el-tags {
  background-color: #9ad2bd !important;
  color: #fff;
  border-color: #9ad2bd;
}
.el-switch.is-checked .el-switch__core {
  background-color: #9ad2bd !important;
  border-color: #9ad2bd;
}
.custom-textarea textarea {  
  height: 120px; /* 或者其他你想要的高度 */  
}
.el-select-dropdown__item.selected {  
    color: #9ad2bd !important; /* 使用 !important 来增加优先级 */  
    font-weight: 700;  
}
.el-table th.el-table__cell>.cell.highlight {
  color: #9ad2bd !important; /* 使用 !important 来增加优先级 */  
}
.el-upload--picture-card:hover{
border-color: #9ad2bd !important;
}
.el-checkbox__input.is-checked 
.el-checkbox__inner, 
.el-checkbox__input.is-indeterminate 
.el-checkbox__inner{
    border-color: #9ad2bd;
  }
.el-checkbox__input.is-checked + .el-checkbox__label {  
    color: #9ad2bd !important;  
}
.el-checkbox__input.is-checked .el-checkbox__inner,  
.el-checkbox__input.is-indeterminate .el-checkbox__inner {  
    border-color: #9ad2bd !important;  
}
.el-checkbox__original{
    border-color: #9ad2bd;
  }
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #9ad2bd !important;
    border-color: #9ad2bd;
  }
.is-disabled:hover {
  color: #9ad2bd !important;
}
.el-checkbox__inner:hover{
  border-color: #9ad2bd !important; 
}
.el-table-filter__bottom button:hover {
  color: #9ad2bd !important;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #9ad2bd !important; 
}
.el-divider--horizontal {
width: 94% !important;
left: 3%;
}
/* .el-button--text {
  color: red !important;
} */
.el-dialog__close:hover{
  color: #9ad2bd  !important;
}
.el-pager li.active {
    color: #9ad2bd !important;
  }
  .el-pager li:hover {
    color: #9ad2bd !important;
}
</style>
