<template>
  <div>
    <div class="dujt">
      {{ nickname }} , {{ greeting }} ! {{ toxicChickenSoup }}
    </div>
    <el-row :gutter="20">
      <el-col :span="6"
        ><div class="grid-content bg-purple gaodu">
          <div class="data-screening">销售产品</div>
          <div class="screening">
            <img src="../assets/产品.png" class="screening-img" />
            <div class="screening-shuju">{{ tableCounts.product }} 款</div>
          </div>
        </div></el-col
      >
      <el-col :span="6"
        ><div class="grid-content bg-purple gaodu">
          <div class="data-screening">品牌新闻</div>
          <div class="screening">
            <img src="../assets/线索.png" class="screening-img" />
            <div class="screening-shuju">{{ tableCounts.news }} 条</div>
          </div>
        </div></el-col
      >
      <el-col :span="6"
        ><div class="grid-content bg-purple gaodu">
          <div class="data-screening">加盟门店</div>
          <div class="screening">
            <img src="../assets/市场.png" class="screening-img" />
            <div class="screening-shuju">{{ tableCounts.store }} 家</div>
          </div>
        </div></el-col
      >
      <el-col :span="6"
        ><div class="grid-content bg-purple gaodu">
          <div class="data-screening">获得荣誉</div>
          <div class="screening">
            <img src="../assets/运营.png" class="screening-img" />
            <div class="screening-shuju">{{ tableCounts.honor }} 项</div>
          </div>
        </div></el-col
      >
    </el-row>
    <div
      id="chartone"
      style="
        width: 49%;
        height: 400px;
        margin-left: 10px;
        margin-top: 50px;
        float: left;
      "
    ></div>
    <div
      id="charttwo"
      style="
        width: 49%;
        height: 400px;
        margin-left: 10px;
        margin-top: 50px;
        float: left;
      "
    ></div>
    <div
      ref="chart"
      style="width: 100%; height: 400px; float: left; margin-left: 12px"
    ></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "home",
  data() {
    return {
      nickname: null,
      account_img: null,
      currentTime: new Date(),
      toxicChickenSoups: [
        "今日份︎甜甜碎片已加载完毕。",
        "保持甜蜜的秘诀有两点,吃我家刨冰和想你。",
        "烘焙里的美味，是一场味蕾的原地旅行。",
        "你身上的温柔，是克莱因的蓝再加上莫奈的一点粉。",
        "你的可爱，治愈了一切的不可爱。",
        "今日份快乐，我要全盘接收。",
        "今日份草莓小可爱在接受你的召唤。",
        "时光清浅处，一岁一安然。",
        "平凡的日子被赋予了特殊的意义❤️。",
        "大老远跑过来 一口下去值了！🤎🤎🤎",
        "上有天堂，下有甜糖。",
        "甜蜜的味道来自港式的纯正。",
        "甜品拂去心底的烦恼，带来恋人般享受。",
        "生活没有那么多甜蜜，更多是平淡，用心去感受那些平淡，一切如糖水般美妙。",
        "生活就像一锅锅糖水，不品尝怎么知道哪款更合适自己",
        "每一份手作糖水都是对这个夏天的抚慰。",
      ],
      toxicChickenSoup: "", // 用于存储毒鸡汤的变量
      tableCounts: {},
      backendData: [],
      backeData: [],
      backeDataseven: [],
    };
  },
  async created() {
    try {
      const response = await this.axios.get(
        "https://service.suxueming.com/SXMAPI/home/tableCounts.php"
      );
      this.tableCounts = response.data;
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    greeting() {
      const hour = this.currentTime.getHours();
      if (hour >= 0 && hour < 6) {
        return "凌晨好"; // 凌晨
      } else if (hour >= 6 && hour < 9) {
        return "早上好"; // 早上
      } else if (hour >= 9 && hour < 12) {
        return "上午好"; // 上午
      } else if (hour >= 12 && hour < 13) {
        return "中午好"; // 中午
      } else if (hour >= 13 && hour < 18) {
        return "下午好"; // 下午
      } else if (hour >= 18 && hour < 20) {
        return "傍晚好"; // 傍晚
      } else {
        return "晚上好"; // 晚上
      }
    },
  },
  mounted() {
    this.loadUserData();
    this.updateTime();
    this.intervalId = setInterval(this.updateTime, 1000 * 60);
    this.toxicChickenSoup =
      this.toxicChickenSoups[
        Math.floor(Math.random() * this.toxicChickenSoups.length)
      ];
    this.drawChartone();
    this.drawCharttwo();
    this.initChart();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    loadUserData() {
      const userDataJSON = localStorage.getItem("userData");
      if (userDataJSON) {
        try {
          const userData = JSON.parse(userDataJSON);
          // console.log(userData);
          this.nickname = userData.username;
          this.account_img = userData.account_img;
        } catch (error) {
          console.error("解析localStorage中的用户数据时出错:", error);
        }
      }
    },
    getLastSevenDates() {
      const dates = [];
      const today = new Date();
      for (let i = 6; i >= 0; i--) {
        const date = new Date();
        date.setDate(today.getDate() - i);
        dates.push(this.formatDate(date));
      }
      return dates;
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    updateTime() {
      this.currentTime = new Date();
    },
    async drawChartone() {
      try {
        const response = await this.axios.get(
          "https://service.suxueming.com/SXMAPI/home/get_chart_data.php"
        );
        this.backendData = response.data;
      } catch (error) {
        console.error(error);
      }
      // 基于准备好的dom，初始化echarts实例
      const myChartone = echarts.init(document.getElementById("chartone"));
      // 指定图表的配置项和数据
      const option = {
        title: {
          text: "今日浏览量",
        },
        tooltip: {},
        xAxis: {
          data: [
            "网站首页",
            "关于我们",
            "产品展示",
            "新闻动态",
            "门店分布",
            "招商加盟",
            "联系我们",
          ],
        },
        yAxis: {},
        series: [
          {
            name: "浏览量",
            type: "bar",
            data: this.backendData,
            itemStyle: {
              color: "#9ad2bd",
            },
            barWidth: "40",
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表
      myChartone.setOption(option);
    },
    async drawCharttwo() {
      try {
        const response = await this.axios.get(
          "https://service.suxueming.com/SXMAPI/home/get_chart_total.php"
        );
        this.backeData = response.data;
      } catch (error) {
        console.error(error);
      }
      // 基于准备好的dom，初始化echarts实例
      const myCharttwo = echarts.init(document.getElementById("charttwo"));
      // 指定图表的配置项和数据
      const option = {
        title: {
          text: "总计浏览量",
        },
        tooltip: {},
        xAxis: {
          data: [
            "网站首页",
            "关于我们",
            "产品展示",
            "新闻动态",
            "门店分布",
            "招商加盟",
            "联系我们",
          ],
        },
        yAxis: {},
        series: [
          {
            name: "浏览量",
            type: "bar",
            data: this.backeData,
            itemStyle: {
              color: "#9ad2bd",
            },
            barWidth: "40",
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表
      myCharttwo.setOption(option);
    },
    async initChart() {
      try {
        const response = await this.axios.get(
          "https://service.suxueming.com/SXMAPI/home/get_chartseven.php"
        );
        this.backeDataseven = response.data;
      } catch (error) {
        console.error(error);
      }
      const map = {
        date: [],
        home: [],
        about: [],
        product: [],
        news: [],
        store: [],
        join: [],
        contact: [],
      };

      this.backeDataseven.forEach((item) => {
        map.date.push(item.date);
        map.home.push(item.home);
        map.about.push(item.about);
        map.product.push(item.product);
        map.news.push(item.news);
        map.store.push(item.store);
        map.join.push(item.join);
        map.contact.push(item.contact);
      });

      this.chart = echarts.init(this.$refs.chart);
      const option = {
        grid: {
          left: "5%", // 设置左边间距为容器宽度的10%
          right: "6%",
          // ... 其他grid属性
        },
        title: {
          text: "近7日浏览量曲线",
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: map.date,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "网站首页",
            data: map.home,
            type: "line",
            itemStyle: {
              color: "#9ad2bd",
            },
          },
          {
            name: "关于我们",
            data: map.about,
            type: "line",
            itemStyle: {
              color: "#9ad2bd",
            },
          },
          {
            name: "产品展示",
            data: map.product,
            type: "line",
            itemStyle: {
              color: "#cae4f0",
            },
          },
          {
            name: "新闻动态",
            data: map.news,
            type: "line",
            itemStyle: {
              color: "#d0aa78",
            },
          },
          {
            name: "门店分布",
            data: map.store,
            type: "line",
            itemStyle: {
              color: "#4c2e7c",
            },
          },
          {
            name: "招商加盟",
            data: map.join,
            type: "line",
            itemStyle: {
              color: "#62875d",
            },
          },
          {
            name: "联系我们",
            data: map.contact,
            type: "line",
            itemStyle: {
              color: "#2775b6",
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
  },
};
</script>

<style scoped lang="stylus">
.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #fafafa;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.dujt {
  width: 96%;
  height: 60px;
  font-size: 24px;
  line-height: 60px;
  margin-left: 2%;
  font-weight: 700;
}

.gaodu {
  height: 150px;
  margin-top: 30px;
}

.data-screening {
  width: 90%;
  height: 80px;
  line-height: 80px;
  font-size: 20px;
  font-weight: 800;
  margin-left: 30px;
  color: #666666;
}

.screening {
  width: 90%;
  height: 70px;
}

.screening-img {
  width: 50px;
  height: 50px;
  margin-left: 30px;
  float: left;
}

.screening-shuju {
  height: 50px;
  font-size: 28px;
  line-height: 50px;
  font-weight: 600;
  margin-left: 90px;
  color: #666666;
}
</style>
